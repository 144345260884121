import { apiTurnos } from '@/core/api/';
import { serialize } from 'object-to-formdata';

export const getDisplays = (payload) =>
  apiTurnos.get('/api/display/search', { params: { ...payload } });

export const getOfficeMonitors = (payload) =>
  apiTurnos.get('/api/display/officemonitors/search', {
    params: { ...payload },
  });

export const getScreenTypes = () =>
  apiTurnos.get('/api/display/screentype/list');

export const submitDisplay = (model) =>
  apiTurnos[model.displayId ? 'put' : 'post']('/api/display', model);

export const saveParameters = (payload) =>
  apiTurnos.post('/api/display/parameters', payload);

export const getDisplay = (id) => apiTurnos.get(`/api/display/${id}`);

export const getDisplayList = (companyId, locationConfigurationId) =>
  apiTurnos.get(`/api/display/${companyId}/${locationConfigurationId}/list`);

export const createDisplayClone = (payload) =>
  apiTurnos.post(`/api/display/${payload.displayId}/clone`, payload);

export const deleteDisplay = (payload) =>
  apiTurnos.delete(`/api/display/${payload.displayId}`, {
    data: { ...payload },
  });

export const submitDisplayLayout = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true,
  });

  return apiTurnos[model.displayId ? 'put' : 'post'](
    '/api/display/layout',
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const setScreenTypeMetadata = (model) =>
  apiTurnos.post(`api/display/${model.displayId}/screentypes`, model);

export const saveCreationStep = (displayId, step) =>
  apiTurnos.post(`api/displayId/${displayId}/creationstep/${step}`);

export const validateDisplayByMacAddress = (displayId, macAddress) => {
  let qstring = '';

  if (displayId) qstring += `displayId=${displayId}`;

  return apiTurnos.get(`/api/display/validate/${macAddress}?${qstring}`);
};

export const deleteImage = (payload) =>
  apiTurnos.delete(`/api/display/${payload.displayId}/image/delete`, {
    data: { ...payload },
  });

export const getScreenInfo = (payload) =>
  apiTurnos.get(
    `api/display/${payload.displayId}/${payload.displayScreenId}/screentype`,
  );

export const getDisplayTypes = () => apiTurnos.get('/api/display/types');
