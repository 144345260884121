<template>
  <div class="row">
    <div class="col-12">
      <validation-observer ref="displayLayoutFormRef" novalidate>
        <div class="row">
          <div class="my-4 col-12">
            <h4 class="font-weight-bolder float-left">
              {{ $t('header') }}
            </h4>
            <hr />
          </div>
          <div class="col-12">
            <div class="card border-light">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 col-lg-4">
                    <custom-image-input
                      v-model="value.layout.headerPrimaryLogo"
                      :auto-delete="false"
                      :file="value.layout.headerPrimaryLogoFile"
                      :label="$t('headerPrimaryLogo')"
                      @clearFile="onDeleteImage('headerPrimaryLogo')"
                    />
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <filter-numeric
                      v-model="value.layout.headerTextSize"
                      measurement-unit="pt"
                      :min="0"
                      :title="$t('textSize')"
                    />
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <filter-color
                      v-model="value.layout.headerTextColor"
                      :label="$t('textColor')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-lg-4">
                    <filter-color
                      v-model="value.layout.headerBackgroundColor"
                      :label="$t('backgroundColor')"
                    />
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <custom-image-input
                      v-model="value.layout.headerBackgroundImage"
                      :auto-delete="false"
                      :file="value.layout.headerBackgroundImageFile"
                      :label="$t('backgroundImage')"
                      @clearFile="onDeleteImage('headerBackgroundImage')"
                    />
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <custom-image-input
                      v-model="value.layout.headerSecondaryLogo"
                      :auto-delete="false"
                      :file="value.layout.headerSecondaryLogoFile"
                      :label="$t('rightSecondaryLogo')"
                      @clearFile="onDeleteImage('headerSecondaryLogo')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-4 col-12">
            <h4 class="font-weight-bolder float-left">
              {{ $t('callbar') }}
            </h4>
            <hr />
          </div>
          <div class="col-12">
            <div class="card border-light">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <filter-color
                      v-model="value.layout.callBarBackgroundColor"
                      :label="$t('backgroundColor')"
                    />
                  </div>
                  <div class="col-md-4">
                    <filter-color
                      v-model="value.layout.callBarTextColor"
                      :label="$t('numberTextColor')"
                    />
                  </div>
                  <div class="col-md-4">
                    <filter-color
                      v-model="value.layout.callBarNameTextColor"
                      :label="$t('nameTextColor')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <filter-color
                      v-model="value.layout.callBarStationTextColor"
                      :label="$t('stationTextColor')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-4 col-12">
            <h4 class="font-weight-bolder float-left">
              {{ $t('sideTurns') }}
            </h4>
            <hr />
          </div>
          <div class="col-12">
            <div class="card border-light">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-12">
                        <filter-color
                          v-model="value.layout.sideTurnBackgroundColor"
                          :label="$t('backgroundColor')"
                        />
                      </div>
                      <div class="col-md-12">
                        <filter-color
                          v-model="
                            value.layout.sideTurnHeaderRowBackgroundColor
                          "
                          :label="$t('headerRowBackgroundColor')"
                        />
                      </div>
                      <div class="col-md-12">
                        <filter-color
                          v-model="value.layout.sideTurnBorderColor"
                          :label="$t('borderColor')"
                        />
                      </div>
                      <div class="col-md-12">
                        <filter-color
                          v-model="
                            value.layout.sideTurnHeaderContentBackgroundColor
                          "
                          :label="$t('turnContentBackgroundColor')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <filter-numeric
                          v-model="value.layout.sideTurnQueueTextSize"
                          :max="999"
                          measurement-unit="pt"
                          :min="0"
                          :title="$t('rowTextSize')"
                        />
                      </div>
                      <div class="col-md-6">
                        <filter-color
                          v-model="value.layout.sideTurnHeaderRowTextColor"
                          :label="$t('headerRowTextColor')"
                        />
                      </div>
                      <div class="col-md-6">
                        <filter-numeric
                          v-model="value.layout.sideTurnNumberTextSize"
                          :max="999"
                          measurement-unit="pt"
                          :min="0"
                          :title="$t('turnNumberTextSize')"
                        />
                      </div>
                      <div class="col-md-6">
                        <filter-color
                          v-model="value.layout.sideTurnNumberTextColor"
                          :label="$t('turnNumberColor')"
                        />
                      </div>
                      <div class="col-md-6">
                        <filter-numeric
                          v-model="value.layout.sideTurnStationTextSize"
                          :max="999"
                          measurement-unit="pt"
                          :min="0"
                          :title="$t('stationTextSize')"
                        />
                      </div>
                      <div class="col-md-6">
                        <filter-color
                          v-model="value.layout.sideTurnStationTextColor"
                          :label="$t('stationColor')"
                        />
                      </div>
                      <div class="col-md-6">
                        <filter-numeric
                          v-model="value.layout.sideTurnNameTextSize"
                          :max="999"
                          measurement-unit="pt"
                          :min="0"
                          :title="$t('nameTextSize')"
                        />
                      </div>
                      <div class="col-md-6">
                        <filter-color
                          v-model="value.layout.sideTurnNameTextColor"
                          :label="$t('nameColor')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-4 col-12">
            <h4 class="font-weight-bolder float-left">
              {{ $t('adsContainer') }}
            </h4>
            <hr />
          </div>
          <div class="col-12">
            <div class="card border-light">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <filter-color
                      v-model="value.layout.adContainerBackgroundColor"
                      :label="$t('backgroundColor')"
                    />
                  </div>
                  <div class="col-md-4">
                    <filter-color
                      v-model="value.layout.adLiabilityReleaseTextColor"
                      :label="$t('adLiabilityReleaseTextColor')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-4 col-12">
            <h4 class="font-weight-bolder float-left">
              {{ $t('monitorFoot') }}
            </h4>
            <hr />
          </div>
          <div class="col-12">
            <div class="card border-light">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <filter-color
                      v-model="value.layout.footerBackgroundColor"
                      :label="$t('footerBackgroundColor')"
                    />
                  </div>
                  <div class="col-md-4">
                    <filter-color
                      v-model="value.layout.footerTextColor"
                      :label="$t('textColor')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
    <div class="my-5 text-right col-12">
      <button
        class="btn btn-default float-left"
        variant="default"
        @click="$emit('continue', -1)"
      >
        <i class="far fa-arrow-alt-circle-left" /> {{ $t('return') }}
      </button>
      <button
        class="btn btn-outline-primary mr-2"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>
      <button class="btn btn-primary" @click="onSubmit(true)">
        {{ $t('finalize') }} <i class="far fa-arrow-alt-circle-right" />
      </button>
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
import CustomImageInput from '@/components/CustomImageInput';
import { submitDisplayLayout as _submitDisplayLayout } from '@/services/DisplayService';
import { deleteImage as _deleteImage } from '@/services/DisplayService';

export default {
  components: {
    CustomImageInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  name: 'DisplayStyles',
  created() {},
  data() {
    return {};
  },
  methods: {
    async onSubmit(next = false) {
      if (!(await this.$refs.displayLayoutFormRef.validate())) return;

      _submitDisplayLayout({
        ...this.value.layout,
        displayId: this.value.displayId,
      })
        .then(() => {
          this.ShowSuccessSaveToast();

          if (next) this.$router.push({ name: 'displaysList' });
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onDeleteImage(imageKey) {
      _deleteImage({
        displayId: this.value.displayId,
        imageKey: imageKey,
      })
        .then(() => {
          this.value.layout[imageKey] = null;
          this.value.layout[`${imageKey}File`] = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>

<style lang="scss" scoped>
h4.font-weight-bolder {
  background-color: $color-app-background;
  padding-right: 10px;
}
</style>
