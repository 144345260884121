<template>
  <div>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="displayNameIsNotCorrect"
      :item="selectedDisplay"
      label="monitorName"
      @on-confirm="onConfirmDelete()"
    />
    <content-header :title="$t('monitors')" />
    <div class="container-fluid">
      <div class="main-page-container" fluid>
        <div class="row">
          <sub-header :title="$t('filterMonitors')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-3"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getDisplays(1)"
        />
      </div>
      <separator class="my-3" :text="$t('monitorsRegistered')" />
      <div class="row">
        <div class="col-12">
          <button
            class="btn btn-primary float-right"
            @click="goToCreateDisplay"
          >
            <i class="fas fa-plus" /> {{ $t('create') }}
          </button>
          <b-button
            class="text-capitalize float-right mr-2"
            variant="outline-success"
            @click="downloadInstaller"
          >
            <i class="fal fa-download" /> {{ $t('downloadInstaller') }}
          </b-button>
        </div>
        <div
          v-for="(display, index) in displays.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="display"
            @on-clone="onCloneDisplay"
            @on-delete="onDeleteDisplay(display)"
            @on-edit="onEditDisplay"
            @on-show-preview="onShowPreview"
          />
        </div>
      </div>
      <custom-pagination
        v-if="displays.count"
        id="pgPager"
        :data="displays"
        :page-size="pageSize"
        @pagination-go-page="getDisplays($event)"
        @pagination-rows-per-page="getDisplays(pageIndex, $event)"
      />
      <CustomNotFound
        v-if="filtered && !displays.data.length"
        :text="$t('noMonitorsFoundForFilter')"
      />

      <preview-modal :id="isVerticalPreview ? 'preview-vertical-modal-ref' : 'preview-modal-ref'" ref="preview-modal-ref" :title="previewTitle" />
    </div>
  </div>
</template>

<script>
import sessionState from '@/mixins/sessionState';
import Separator from '@/components/Separator';
import FilterManager from '@/components/FilterManager';
import CustomPagination from '@/components/CustomPagination.vue';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import contentHeader from '@/components/Header';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import PreviewModal from '@/components/PreviewModal.vue';
import SubHeader from '@/components/SubHeader.vue';
import SearchResult from './components/SearchResult';
import { getDisplays as _getDisplays } from '@/services/DisplayService';
import { deleteDisplay as _deleteDisplay } from '@/services/DisplayService';
import { getMacInstaller } from '@/services/KioskService';
import DeleteModal from '@/components/DeleteModal';
import { DISPLAY } from '@/config';
import DisplayType from '@/constants/DisplayType';

export default {
  name: 'DisplaysList',
  components: {
    FilterManager,
    CustomPagination,
    contentHeader,
    CustomNotFound,
    SubHeader,
    Separator,
    SearchResult,
    DeleteModal,
    PreviewModal,
  },
  created() {},
  data() {
    return {
      filters: {
        location: [],
        companies: [],
      },
      selectedFilters: {
        location: [],
        company: [],
        name: '',
      },
      displays: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      filtered: false,
      deleteConfirmationDisplayName: null,
      macInstallerUrl: '',
      showDeleteModal: false,
      selectedDisplay: null,
      previewTitle: null,
      pageIndex: 1,
      isVerticalPreview: null
    };
  },
  mixins: [sessionState],
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          error: this.$t('mustselectcompany'),
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          rules: '',
          name: 'location',
          label: this.$t('localization'),
          component: 'FilterMultiSelect',
          options: this.filters.location,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.location,
          onChanged: this.onLocationChanged,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('monitorName'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-4',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data;
          if (this.filters.companies.length == 1) {
            this.getAvailableLocalizations(
              this.selectedFilters.company[0].value,
            );
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      await getMacInstaller().then(
        (resp) => (this.macInstallerUrl = resp.data),
      );
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.location = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.location = [];
      this.filters.location = [];
      this.selectedFilters.company = [];
      if (value) {
        this.selectedFilters.company = [value];
        this.getAvailableLocalizations(this.selectedFilters.company[0].value);
      }
    },
    onLocationChanged(value) {
      if (value == null) {
        this.selectedFilters.location = [];
        return;
      }
      this.selectedFilters.location = [value];
    },
    async getDisplays(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        CompanyId: this.selectedFilters.company.length
          ? this.selectedFilters.company[0].value
          : null,
        LocationConfigurationId: this.selectedFilters.location.length
          ? this.selectedFilters.location[0].id
          : null,
        Name: this.selectedFilters.name || null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getDisplays(payload)
        .then(({ data }) => {
          this.displays = data;
          this.filtered = true;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async goToCreateDisplay() {
      // Persist current state of this view
      this.saveSessionData('displayData', Object.assign({}, this.$data));

      this.$router.push({ name: 'displayCreate' });
    },
    async onEditDisplay(display) {
      // Persist current state of this view
      this.saveSessionData('displayData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'displayEdit',
        params: { id: display.displayId },
      });
    },
    async onCloneDisplay(display) {
      // Persist current state of this view
      this.saveSessionData('displayData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'displayClone',
        params: { id: display.displayId },
      });
    },
    onShowPreview(display) {
      if (display.macAddressList && display.macAddressList != '') {
        this.isVerticalPreview = display.displayTypeId == DisplayType.Vertical;
        const splitIndex = display.macAddressList.indexOf(',');

        // Show the modal
        this.previewTitle = display.name;

        this.$refs['preview-modal-ref'].show(
          `${DISPLAY.BASE_URL}?macAddress=${encodeURIComponent(
            display.macAddressList.slice(
              0,
              splitIndex == -1 ? display.macAddressList.length : splitIndex,
            ),
          )}&preview=1`,
        );
      }
    },
    onDeleteDisplay(display) {
      this.showDeleteModal = true;
      this.selectedDisplay = display;
    },
    async onConfirmDelete() {
      await _deleteDisplay(this.selectedDisplay)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.getDisplays();
          this.selectedDisplay = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    downloadInstaller() {
      let downloadWindow = window.open(this.macInstallerUrl);
      if (downloadWindow) {
        this.ShowSuccessToast(
          this.$t('downloadStartedSuccessful'),
          this.$t('findDownloadLocally'),
        );
      } else {
        this.ShowToast(
          this.$t('downloadError'),
          this.$t('downloadErrorMessage'),
          'error',
        );
      }
    },
  },
  async mounted() {
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData('displayData');
    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      this.loadInitialFilters();
    }
  },
};
</script>

<style lang="scss">
#preview-modal-ref > .modal-dialog {
  margin: 0;
  margin-left: 8%;
}

#preview-modal-ref > .modal-dialog .modal-content {
  min-width: 1280px;
  min-height: 846px;
  overflow: hidden;
}

#frmPreviewContainer {
  width: 100%;
  height: 95%;
  position: absolute;
  overflow: hidden;
}

#preview-vertical-modal-ref .modal-dialog .modal-content {
  max-width: 850px !important;
  min-height: 2100px !important;
}

#preview-vertical-modal-ref .modal-body {
  padding: 0 !important;
}

#preview-vertical-modal-ref #frmPreviewContainer {
  max-width: 848px !important;
  min-height: 2050px !important;
  margin-top: 0 !important;
}

#preview-vertical-modal-ref #frmPreview {
  width: 100% !important;
}

</style>
