<template>
  <div class="mt-5 container-fluid">
    <step-wizard
      :cascade-activation="true"
      class="mb-3"
      :step="stepIndex"
      :tabs="tabs"
      @OnStepChange="onStepChange($event)"
    />

    <ul
      id="tab-controls"
      class="nav nav-pills"
      role="tablist"
    >
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive(0) }"
          href="#create"
          @click.prevent="onStepChange(0)"
        />
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive(1) }"
          href="#styles"
          @click.prevent="onStepChange(1)"
        />
      </li>
    </ul>

    <div id="tab-container">
      <div
        id="create"
        class="tab-pane fade"
        :class="{ 'active show': isActive(0) }"
        role="tabpanel"
      >
        <create
          class="my-3"
          :value="display"
          @continue="onContinue($event)"
          @submmited="loaddisplay(displayId)"
        />
      </div>
      <div
        id="styles"
        class="tab-pane"
        :class="{ 'active show': isActive(1) }"
        role="styles"
      >
        <DisplayStyles
          :value="display"
          @continue="onContinue($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StepWizard from '@/components/StepWizard';
import Create from './Create';
import { getScreenTypes as _getScreenTypes } from '@/services/DisplayService';
import { getDisplay as _getDisplay } from '@/services/DisplayService';
import DisplayStyles from './DisplayStyles.vue';

export default {
  name: 'DisplayMaster',
  components: {
    Create,
    StepWizard,
    DisplayStyles,
  },
  data() {
    return {
      stepIndex: 0,
      display: {
        displayId: null,
        companyId: null,
        locationConfigurationId: null,
        locationConfigurationName: null,
        name: null,
        macAddress: [],
        backgroundMessage: null,
        displayBackgroundMessage: false,
        backgroundMessageTextSize: null,
        displayName: false,
        displayTurnNumber: false,
        displayLiabilityRelease: false,
        displayMessageCost: false,
        displayFollowUpMessage: false,
        displayTypeId: null,
        displayVillage: false,
        enableVoice: false,
        ringtoneVolume: 0,
        videoVolume: 0,
        showTimeInformation: false,
        timeInformationTextSize: null,
        displayClientNameOnRibbon: true,
        displayTurnNumberOnRibbon: true,
        layout: {
          displayId: 0,
          displayDesignConfigurationId: 0,
          headerBackgroundColor: null,
          headerBackgroundImage: null,
          headerBackgroundImageFile: null,
          headerPrimaryLogo: null,
          headerPrimaryLogoFile: null,
          headerSecondaryLogo: null,
          headerSecondaryLogoFile: null,
          headerTextColor: null,
          headerTextSize: null,
          callBarBackgroundColor: null,
          callBarNameTextColor: null,
          callBarStationTextColor: null,
          callBarTextColor: null,
          sideTurnNameTextColor: null,
          sideTurnBorderColor: null,
          sideTurnHeaderRowBackgroundColor: null,
          sideTurnHeaderContentBackgroundColor: null,
          sideTurnHeaderRowTextColor: null,
          sideTurnNumberTextColor: null,
          sideTurnStationTextColor: null,
          adContainerBackgroundColor: null,
          adLiabilityReleaseTextColor: null,
          footerBackgroundColor: null,
          footerTextColor: null,
          sideTurnQueueTextColor: null,
          sideTurnQueueTextSize: null
        },
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.name === 'displayEdit') {
          const displayId = Number(val.params.id);
          this.loadDisplay(displayId);
          this.display.displayId = displayId;
        } else {
          this.display.displayId = null;
        }
      },
    },
  },
  props: {},
  computed: {
    tabs() {
      return [
        {
          name: this.$t('monitorCreation'),
          icon: 'far fa-plus-square',
          disabled: false,
        },
        {
          name: this.$t('monitorStyles'),
          icon: 'far fa-tv-alt',
          disabled: !this.display.displayId,
        },
      ];
    },
  },
  methods: {
    async loadScreenType() {
      await _getScreenTypes()
        .then((response) => (this.screenTypes = response.data))
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    loadDisplay(id) {
      _getDisplay(id)
        .then((response) => {
          const data = response.data;
          const layout =
            {
              ...data.displayLayoutDesignConfiguration,
              headerLogo: null,
              headerBackgroundImage: null,
              headerPrimaryLogo: null,
              headerSecondaryLogo: null,
            } || this.display.layout;

          this.display = {
            ...this.display,
            displayId: data.displayId,
            companyId: data.companyId,
            locationConfigurationId: data.locationConfigurationId,
            locationConfigurationName: data.locationConfigurationName,
            name: data.name,
            macAddress: data.macAddress,
            backgroundMessage: data.backgroundMessage,
            displayBackgroundMessage: data.displayBackgroundMessage,
            displayName: data.displayName,
            displayTurnNumber: data.displayTurnNumber,
            displayLiabilityRelease: data.displayLiabilityRelease,
            displayMessageCost: data.displayMessageCost,
            displayFollowUpMessage: data.displayFollowUpMessage,
            displayTypeId: data.displayTypeId,
            displayVillage: data.displayVillage,
            enableVoice: data.enableVoice,
            ringtoneVolume: data.ringtoneVolume,
            videoVolume: data.videoVolume,
            showTimeInformation: data.showTimeInformation,
            displayClientNameOnRibbon: data.displayClientNameOnRibbon,
            displayTurnNumberOnRibbon: data.displayTurnNumberOnRibbon,
            timeInformationTextSize: data.timeInformationTextSize,
            backgroundMessageTextSize: data.backgroundMessageTextSize,
            layout,
          };
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.$router.push({ name: 'displaysList' });
        });
    },
    onContinue(event) {
      const isNegative = event < 0;
      const nextPosition = this.stepIndex + event;
      if (nextPosition === -1) this.$router.push({ name: 'displaysList' });

      if (isNegative) this.stepIndex = nextPosition;
      else {
        if (this.stepIndex === 0) this.stepIndex = nextPosition;
        else if (this.stepIndex === 1) this.stepIndex = nextPosition;
      }
    },
    isActive(step) {
      return this.stepIndex == step;
    },
    onStepChange(step) {
      this.stepIndex = step;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav,
.nav-pills {
  display: none;
}
.tab-pane {
  display: none;
}
.tab-pane.active.show {
  display: block;
}
</style>
