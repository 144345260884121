<template>
  <div class="row">
    <content-header :title="$t('monitorCreation')" />
    <div class="col-12">
      <div class="card p-4" style="border: white">
        <validation-observer ref="displayFormRef" novalidate>
          <div class="row">
            <div class="col-lg-4 col-md-4 mt-2">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="value.companyId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="text"
                  fieldvalue="value"
                  :label="$t('company')"
                  :options="collections.companies"
                />
              </validation-provider>
            </div>
            <div class="col-lg-4 col-md-4 mt-2">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="value.locationConfigurationId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('activeLocation')"
                  :options="collections.locations"
                />
              </validation-provider>
            </div>
            <div class="col-lg-4 col-md-4 mt-2">
              <validation-provider v-slot="{ errors }" rules="required|max:64">
                <base-input
                  v-model="value.name"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('monitorName')"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 mt-2">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="value.displayTypeId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('displayType')"
                  :options="collections.displayTypes"
                />
              </validation-provider>
            </div>

            <div class="col-lg-8 col-md-8 float-right mt-3">
              <button
                v-if="hideSubmitButton === undefined"
                class="btn btn-primary float-right mt-4"
                @click="onSubmitDisplay()"
              >
                <i class="far fa-save" /> {{ $t('save') }}
              </button>
              <button
                class="btn btn-outline-primary mt-4 float-right mx-2"
                @click="showMacAddressManager()"
              >
                <i class="far fa-plus-square" /> {{ $t('macAddress') }}
              </button>
            </div>
          </div>
        </validation-observer>
      </div>
    </div>
    <slot />

    <div v-if="editMode" class="col-12">
      <validation-observer ref="displayParametersFormRef" novalidate>
        <div class="row">
          <div class="col-12 my-4">
            <h4 class="font-weight-bolder float-left">
              {{ $t('parameters') }}
            </h4>
            <hr />
          </div>
          <div class="col-12">
            <div class="card p-4" style="border: white">
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('voiceOnMonitor') }}</label>
                </div>
                <div class="col-lg-3 col-md-5">
                  <b-checkbox v-model="value.enableVoice" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('showName') }}</label>
                </div>
                <div class="col-lg-3 col-md-5">
                  <b-checkbox v-model="value.displayName" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('showTurnNumber') }}</label>
                </div>
                <div class="col-lg-3 col-md-5">
                  <b-checkbox v-model="value.displayTurnNumber" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('videoVolume') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <input
                    v-model="value.videoVolume"
                    class="slider"
                    max="100"
                    min="1"
                    type="range"
                  />
                </div>
                <div class="col">
                  {{ value.videoVolume }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('ringVolume') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <input
                    v-model="value.ringtoneVolume"
                    class="slider"
                    max="100"
                    min="1"
                    type="range"
                  />
                </div>
                <div class="col">
                  {{ value.ringtoneVolume }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('displayFollowUpMessage') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.displayFollowUpMessage" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('DisplayMessageCost') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.displayMessageCost" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('sampleTown') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.displayVillage" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('showLiabilityRelease') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.displayLiabilityRelease" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('displayMessageOnMonitorBackground') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.displayBackgroundMessage" />
                </div>
              </div>

              <div v-if="value.displayBackgroundMessage" class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('messageOnMonitorBackground') }}</label>
                </div>
                <div class="col-md-5">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <base-input
                      v-model="value.backgroundMessage"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :max-length="512"
                      :textarea="true"
                    />
                  </validation-provider>
                </div>
              </div>
              <div v-if="value.displayBackgroundMessage" class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('backgroundMessageTextSize') }}</label>
                </div>
                <div class="col-md-5">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-numeric
                      v-model="value.backgroundMessageTextSize"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :max="999"
                      measurement-unit="pt"
                      :min="0"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('showTimeInformation') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.showTimeInformation" />
                </div>
              </div>
              <div v-if="value.showTimeInformation" class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('timeInformationTextSize') }}</label>
                </div>
                <div class="col-md-5">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-numeric
                      v-model="value.timeInformationTextSize"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :max="999"
                      measurement-unit="pt"
                      :min="0"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('displayClientNameOnRibbon') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.displayClientNameOnRibbon" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-2 col-md-5">
                  <label>{{ $t('displayTurnNumberOnRibbon') }}</label>
                </div>
                <div class="col-lg-2 col-md-5">
                  <b-checkbox v-model="value.displayTurnNumberOnRibbon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
    <div class="my-5 float-right col-12">
      <base-filled-button
        bg-color="#707070"
        class="float-left"
        icon-class="fas fa-ban"
        :on-click="OnCancel"
        :text="$t('cancel')"
      />
      <button
        v-if="editMode"
        class="btn btn-primary float-right mx-2"
        @click="onSaveParameters(true)"
      >
        {{ $t('saveContinue') }} <i class="far fa-arrow-alt-circle-right" />
      </button>
      <button
        v-if="editMode"
        class="btn btn-outline-primary float-right"
        variant="outline-primary"
        @click="onSaveParameters()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <slot name="buttons" />
    </div>
    <mac-address-manager
      ref="macAddressManager"
      v-model="this.value.macAddress"
      :on-mac-address-validating="onMacAddressValidating"
    />
  </div>
</template>

<script>
import sessionState from '@/mixins/sessionState';
import BaseInput from '@/components/BaseInput.vue';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import contentHeader from '@/components/Header';
import { deleteImage as _deleteImage } from '@/services/DisplayService';
import {
  submitDisplay as _submitDisplay,
  saveParameters as _saveParameters,
} from '@/services/DisplayService';
import { validateDisplayByMacAddress as _validateDisplayByMacAddress } from '@/services/DisplayService';
import { getDisplayTypes as _getDisplayTypes } from '@/services/DisplayService';
import MacAddressManager from '@/components/MacAddressManager';

export default {
  name: 'DisplayCreate',
  components: { BaseInput, contentHeader, MacAddressManager },
  created() {},
  data() {
    return {
      collections: {
        companies: [],
        locations: [],
        displayTypes: [],
      },
      edit: false,
      videoVolume: 0,
      ringVolume: 0,
    };
  },
  mixins: [sessionState],
  computed: {
    editMode() {
      return this.$route.name === 'displayEdit';
    },
    cloneMode() {
      return this.$route.name === 'displayClone';
    },
  },
  mounted() {
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData(
      'registeredDisplayData',
    );
    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      this.loadInitialFilters();
    }
  },
  watch: {
    'value.companyId': {
      immediate: true,
      handler(value) {
        this.collections.locations = [];
        if (value) this.getAvailableLocalizations(value);
      },
    },
    'value.displayClientNameOnRibbon': {
      immediate: true,
      handler(newVal) {
        if (!newVal && !this.value.displayTurnNumberOnRibbon) {
          this.value.displayTurnNumberOnRibbon = true;
        }
      },
    },
    'value.displayTurnNumberOnRibbon': {
      immediate: true,
      handler(newVal) {
        if (!newVal && !this.value.displayClientNameOnRibbon) {
          this.value.displayClientNameOnRibbon = true;
        }
      },
    },
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    hideSubmitButton: {
      type: undefined,
      default: () => undefined,
    },
  },
  methods: {
    async onSubmitDisplay(next = false) {
      if (!(await this.$refs.displayFormRef.validate())) return;

      if (!this.value.macAddress.length) {
        this.ShowToast('Error', this.$t('macAddressError'), 'error');
        return;
      }

      const payload = {
        displayId: this.value.displayId,
        companyId: this.value.companyId,
        locationConfigurationId: this.value.locationConfigurationId,
        name: this.value.name,
        macAddress: this.value.macAddress,
        displayTypeId: this.value.displayTypeId,
      };

      _submitDisplay(payload)
        .then((response) => {
          this.ShowSuccessSaveToast();

          if (!next) {
            const location = this.collections.locations.find(
              (x) => x.id === this.value.locationConfigurationId,
            );
            if (location) this.value.locationConfigurationName = location.name;

            if (this.editMode) this.$emit('submitted');
            else
              this.$router.push({
                name: 'displayEdit',
                params: { id: response.data },
              });
            return;
          }

          if (next) this.$emit('continue', 1);
          if (this.value.layout.displayDesignConfigurationId) return;
          this.value.layout.displayDesignConfigurationId = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      // Persist current state of this view
      this.saveSessionData(
        'registeredDisplayData',
        Object.assign({}, this.$data),
      );
    },

    async OnCancel() {
      this.$router.push({ name: 'displaysList' });
    },

    async onSaveParameters(next = false) {
      if (!this.value.displayName && !this.value.displayTurnNumber) {
        this.ShowErrorToast(this.$t('displayNameOrNumberRequired'));
        return;
      }

      const payload = {
        displayId: this.value.displayId,
        backgroundMessage: this.value.backgroundMessage,
        displayBackgroundMessage: this.value.displayBackgroundMessage,
        displayFollowUpMessage: this.value.displayFollowUpMessage,
        displayMessageCost: this.value.displayMessageCost,
        displayName: this.value.displayName,
        displayTurnNumber: this.value.displayTurnNumber,
        displayLiabilityRelease: this.value.displayLiabilityRelease,
        displayVillage: this.value.displayVillage,
        enableVoice: this.value.enableVoice,
        ringtoneVolume: parseInt(this.value.ringtoneVolume),
        videoVolume: parseInt(this.value.videoVolume),
        showTimeInformation: this.value.showTimeInformation,
        displayClientNameOnRibbon: this.value.displayClientNameOnRibbon,
        displayTurnNumberOnRibbon: this.value.displayTurnNumberOnRibbon,
        backgroundMessageTextSize: this.value.backgroundMessageTextSize,
        timeInformationTextSize: this.value.timeInformationTextSize,
      };

      _saveParameters(payload)
        .then(() => {
          this.ShowSuccessSaveToast();

          if (next) this.$emit('continue', 1);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      // Persist current state of this view
      this.saveSessionData(
        'registeredDisplayData',
        Object.assign({}, this.$data),
      );
    },

    async loadInitialFilters() {
      await _getCompanies()
        .then((response) => {
          this.collections.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      await _getDisplayTypes()
        .then((response) => {
          this.collections.displayTypes = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.collections.locations = response.data;
          if (this.value.locationConfigurationId) {
            const location = this.collections.locations.find(
              (x) => x.id === this.value.locationConfigurationId,
            );
            if (!location) this.value.locationConfigurationId = null;
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    showMacAddressManager() {
      this.$refs.macAddressManager.show();
    },
    async onMacAddressValidating(item) {
      var result = await _validateDisplayByMacAddress(
        this.value.displayId || null,
        item,
      )
        .then((response) => {
          if (response.data == '') return true; // Mac Address is not duplicated

          this.ShowToast(
            'Error',
            `${this.$t('macAddressExits')}: [${response.data.name}]
            [${response.data.companyName}] [${
              response.data.locationConfigurationName
            }] `,
            'error',
          );
          return false; // Mac Address is not valid
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
          return false;
        });

      return result;
    },

    async onDeleteImage(imageKey) {
      _deleteImage({
        displayId: this.value.displayId,
        imageKey: imageKey,
      })
        .then(() => {
          this.value.layout[imageKey] = null;
          this.value.layout[`${imageKey}File`] = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>

<style lang="scss" scoped>
h4.font-weight-bolder {
  background-color: $color-app-background;
  padding-right: 10px;
}
.title {
  font-size: 14px;
  color: $color-primary;
}
.macadddress-list {
  border: 1px solid $color-label-default !important;
  border-radius: 5px;
}
.btnStyle {
  font: normal normal normal 10px Lato;
  padding: 5px;
}
.custom-range {
  border-radius: 10px;
}
input[type='range'] {
  outline: 0;
  border: 0;
  border-radius: 500px;
  width: 100%;
  max-width: 100%;
  transition: box-shadow 0.2s ease-in-out;
  // Chrome
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    & {
      overflow: hidden;
      height: 1.4rem;
      -webkit-appearance: none;
      background-color: #ddd;
    }
    &::-webkit-slider-runnable-track {
      height: 1.4rem;
      -webkit-appearance: none;
      color: #444;
      transition: box-shadow 0.2s ease-in-out;
    }
    &::-webkit-slider-thumb {
      width: 1.4rem;
      -webkit-appearance: none;
      height: 1.4rem;
      cursor: ew-resize;
      background: #fff;
      box-shadow: -330px 0 0 320px #ff8105, inset 0 0 0 40px #ff8105;
      border-radius: 50%;
      transition: box-shadow 0.2s ease-in-out;
      position: relative;
    }
    &:active::-webkit-slider-thumb {
      background: #fff;
      box-shadow: -330px 0 0 320px #ff8105, inset 0 0 0 3px #ff8105;
    }
  }
  // Firefox
  &::-moz-range-progress {
    background-color: #ff8105;
  }
  &::-moz-range-track {
    background-color: #444;
  }
  // IE
  &::-ms-fill-lower {
    background-color: #ff8105;
  }
  &::-ms-fill-upper {
    background-color: #ff8105;
  }
}
</style>
