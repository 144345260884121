<template>
  <div class="mt-5 container-fluid">
    <step-wizard
      :cascade-activation="true"
      class="mb-5"
      :step="0"
      :tabs="Tabs"
    />
    <div class="row">
      <div class="col-md-12">
        <validation-observer
          ref="cloneFormRef"
          novalidate
        >
          <create
            class="my-3"
            hide-submit-button
            :value="display"
            @continue="onContinue($event)"
          >
            <div class="mt-3 col-12">
              <div
                border-variant="light"
                class="card p-3"
                style="border: white"
              >
                <div class="row">
                  <div class="col-md-6">
                    <b-form-checkbox
                      v-model="display.copyColors"
                      switch
                    >
                      {{ $t('copyColors') }}
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="display.copyLogos"
                      switch
                    >
                      {{ $t('copyLogos') }}
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="display.copyImages"
                      switch
                    >
                      {{ $t('copyImages') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <template v-slot:buttons>
              <button
                class="btn btn-primary float-right"
                @click="onSubmit()"
              >
                <i class="far fa-save" /> {{ $t('clone') }}
              </button>
            </template>
          </create>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import Create from './Create';
import StepWizard from '@/components/StepWizard';
import { getDisplay as _getDisplay } from '@/services/DisplayService';
import { createDisplayClone as _createDisplayClone } from '@/services/DisplayService';

export default {
  name: 'DisplayClone',
  components: {
    StepWizard,
    Create,
  },
  created() {},
  data() {
    return {
      display: {
        displayId: null,
        companyId: null,
        displayTypeId: null,
        locationConfigurationId: null,
        locationConfigurationName: null,
        name: null,
        macAddress: [],
        copyColors: true,
        copyLogos: true,
        copyImages: true,
      },
    };
  },
  mounted() {
    this.display.displayId = Number(this.$route.params.id);
    this.loadDisplay(this.display.displayId);
  },
  computed: {
    Tabs() {
      return [
        {
          name: this.$t('monitorCreation'),
          icon: 'far fa-plus-square',
          disabled: false,
        },
        {
          name: this.$t('monitorStyles'),
          icon: 'far fa-tv-alt',
          disabled: !this.display.displayId,
        },
      ];
    },
  },
  props: {},
  methods: {
    loadDisplay(id) {
      _getDisplay(id)
        .then((response) => {
          const data = response.data;
          this.display = {
            ...this.display,
            companyId: data.companyId,
            locationConfigurationId: data.locationConfigurationId,
            locationConfigurationName: data.locationConfigurationName,
            displayTypeId: data.displayTypeId,
            name: `${data.name} - copy`,
            macAddress: [],
          };
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.$router.push({ name: 'displaysList' });
        });
    },
    onContinue(event) {
      if (event === -1) this.$router.push({ name: 'displaysList' });
    },
    async onSubmit() {
      if (!(await this.$refs.cloneFormRef.validate())) return;
      
      if (!this.display.macAddress.length)
        return this.ShowToast('Error', this.$t('macAddressError'), 'error');

      _createDisplayClone(this.display).then((response) => {
        this.ShowToast(
          this.$t('success'),
          this.$t('displayClonedSuccessfully'),
          'success',
        );
        this.$router.push({
          name: 'displayEdit',
          params: { id: response.data },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
